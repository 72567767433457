@import "scss/reset";
@import "scss/base";
@import "scss/loader";
@import "scss/home";
@import "scss/help";
@import "scss/final";
@import "scss/btn";
@import "scss/text-point";

::-webkit-scrollbar {
  display: none;
}
body.scroll {
  height: 4500vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  #container {
    pointer-events: none;
  }
}
