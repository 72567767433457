body {
  font-family: "Soleil";
  font-size: 16px;
  background: #000;
  color: #eee;
}

#container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 750px) {
  .container {
    padding: 0 24px;
  }
}
