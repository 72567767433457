.loader {
  z-index: 4;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Soleil;
  color: #ccc;
  font-size: 24px;

  .wrapper {
    overflow: hidden;
  }
}

@media (max-width: 1200px) {
  .loader {
    font-size: 18px;
  }
}
@media (max-width: 756px) {
  .loader {
    font-size: 14px;
  }
}
