.help {
  position: fixed;
  top: 10vh;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
  opacity: 1;
  pointer-events: none;
  font-family: Soleil;
  font-size: 20px;
  text-transform: uppercase;
  opacity: 1;
  color: #ccc;
  div {
    position: absolute;
    width: 600px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    opacity: 1;
    overflow: hidden;
  }
}

.help__scroll {
  .scroll1 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    opacity: 1;
  }
  .scroll2 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    opacity: 1;
  }
}

.help__click {
  p {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    opacity: 1;
  }
}

.help__play {
  p {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    opacity: 1;
  }

  .mobile {
    opacity: 0;
  }
}

@media (max-width: 1200px) {
  .help {
    font-size: 16px;
  }
}
@media (max-width: 756px) {
  .help {
    font-size: 12px;
  }
}
