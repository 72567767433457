.home {
  // display: none;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Soleil;
  color: #ccc;
}

.home__title {
  position: absolute;
  overflow: hidden;
  transform-origin: center bottom;
  top: 45%;
  font-size: 24px;
  h1 {
    text-align: center;
    color: #ccc;
    text-transform: uppercase;

    transform: translateY(-100%);
  }
}

.home__subtitle {
  // max-width: 600px;
  overflow: hidden;
  z-index: 2;
  transform-origin: center top;
  transform: rotateX(90deg);
  position: absolute;
  top: 45%;
  font-size: 24px;
  h2 {
    text-align: center;
    color: #ccc;
    opacity: 1;
  }
}

.home__start {
  z-index: 1;
  font-family: Soleil;
  text-transform: uppercase;
  transition: opacity 0.1s ease;
  opacity: 1;
  overflow: hidden;
  margin-top: 100px;
  p {
    font-size: 24px;
    color: #ccc;

    transform: translateY(-100%);
  }

  &:hover {
    opacity: 0.3;
  }
}

.info {
  position: fixed;
  z-index: 3;
  top: 10px;
  left: 10px;
  display: none;
}

.rotate {
  display: none;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Soleil;
  background: #000;
  color: #ccc;
  p {
    text-transform: uppercase;
    margin-top: -100px;
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .home__title {
    margin-top: 0;
    font-size: 18px;
  }
  .home__subtitle {
    margin-top: 0;
    font-size: 18px;
  }

  .home__start {
    margin-top: 100px;

    p {
      font-size: 18px;
    }
  }
}
@media (max-width: 756px) {
  .home__title {
    font-size: 14px;
  }
  .home__subtitle {
    font-size: 14px;
  }

  .home__start {
    margin-top: 50px;
    p {
      font-size: 14px;
    }
  }
}
